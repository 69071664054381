<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <div class="article" v-for="count in data.records" :key="count.id" @click="toDetails(count.id)">
          <img :src=count.pic>
          <div class="content">
            <h4>{{ count.title }}</h4>
            <p>{{ count.brief }}</p>
            <p>{{ count.time }}&nbsp; | &nbsp; 来源：{{ count.source }}</p>
          </div>
        </div>
        <pagination v-on:toPage="toPage" :this-page="current" :pages="data.pages"></pagination>
      </div>
      <div slot="rightDiv" class="right">
        <card ref="card">
          <div slot="heard" class="search">
            <input placeholder="搜索">
            <i><img src="../../assets/image/icon/search.png"></i>
          </div>
        </card>
        <card ref="card1">
        </card>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";
import card from "@/components/public/card";
import pagination from "@/components/public/pagination";

export default {
  name: "importing",
  components: {
    leftAndRight,
    card,
    pagination
  },
  data() {
    return {
      data: {},
      current: 1
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$http.get('/safe/getArticleList', {
        params: {
          columnName: '重要活动',
          belong: 1,
          current: this.current,
          size: 5
        }
      }).then((res) => {
        let forms = res.data.records
        for (let i in forms) {
          let pic = forms[i].pic
          if (pic != null) {
            forms[i].pic = this.$constContent.imgUrl + pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          forms[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
        }
        this.data = res.data
      })


        const ths = this
        this.$http.get('/safe/getIndexInformation', {
          params: {
            name: '热门活动',
          }
        }).then((res) => {
          let forms = res.data

          for (let i in forms) {
            let pic = forms[i].pic
            if (pic != null) {
              forms[i].pic = this.$constContent.imgUrl + pic
            }
            let time = forms[i].time;
            let date = new Date(time);
            res.data[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
          }
          let Cards = []
          let Card = {}
          Card.title = '热门活动'
          Card.id = 0
          Card.content = res.data
          Cards.push(Card)
          ths.$nextTick(() => {
            ths.$refs.card.init(Cards)
          })
        })
      this.$http.get('/safe/getIndexInformation', {
        params: {
          name: '往期活动',
        }
      }).then((res) => {
        let forms = res.data

        for (let i in forms) {
          let pic = forms[i].pic
          if (pic != null) {
            forms[i].pic = this.$constContent.imgUrl + pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          res.data[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
        }
        let Cards = []
        let Card = {}
        Card.title = '往期活动'
        Card.id = 0
        Card.content = res.data
        Cards.push(Card)
        ths.$nextTick(() => {
          ths.$refs.card1.init(Cards)
        })
      })
    },
    toPage(e) {
      this.current = e
      this.getDataList()
    },
    toDetails(id) {
      this.$router.push('/trainingDetails?id=' + id)
      this.$emit('getTitle', '活动详情')
    }
  }
}
</script>

<style scoped>
.article {
  width: 98%;
  height: 200px;
  border-bottom: 1px solid #eeeeee;
  margin-right: 2%;
  margin-bottom: 20px;
}

.content img {
  width: 20%;
  float: left;
  background-size: 100% 100%;
  color: #ffffff;
  cursor: pointer;
}

.content {
  width: calc(80% - 10px);
  float: left;
  margin-left: 10px;
  cursor: pointer;
}

.article .content p {
  line-height: 24px;
  color: #666666;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.content h3 {
  line-height: 25px;
  height: 50px;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.search {
  width: 100%;
  height: 40px;
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
}

.search i img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 12px;
}

.right img {
  width: 100%;
}
</style>